import React from 'react'

import HeroBanner from '../../../react4xp/components/HeroBanner'
import Button from '../../../react4xp/components/Button'

function HeaderSection({heading, intro, text, imageSrc, imageSrcMedium, imageSrcSmall, youtubeVideoId, primaryLink, primaryLinkText, secondaryLink, secondaryLinkText}) {
  return (
    <HeroBanner
        title={heading}
        intro={intro}
        imgSrc={imageSrc?.link}
        imgSrcMedium={imageSrcMedium?.link}
        imgSrcSmall={imageSrcSmall?.link}
        imgWidth={2688}
        imgAlt={imageSrc?.alt}
        imgCaption={imageSrc?.caption}
        youtubeVideoId={youtubeVideoId}
        autoplay={false}
        text={text}
        buttons={[
            (primaryLink && primaryLinkText) ? <Button href={primaryLink} variant='primary' size='md' text={primaryLinkText} /> : <></>,
            (secondaryLink && secondaryLinkText) ? <Button href={secondaryLink} variant='secondary' size='md' text={secondaryLinkText} /> : <></>,
        ]}
    />
  )
}

export default (props) => <HeaderSection {...props} />
